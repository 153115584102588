import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { AnnotatedImage, FieldTicket, FieldTicketGraphComparisonData, FieldTicketGraphComparisonOvalityPoints, FieldTicketGraphData, FieldTicketSearch, RecentFieldTicketSearch } from './models/field-ticket.model';
import { Customer, CustomerCreate, CustomerSearch, CustomerUpdate } from './models/customer';
import { OvalityInput, OvalityInputImage } from './models/ovality-input.model';


// Load current customer information
export const actionLoadCustomer = createAction(
  '[Dashboard] Load Customer',
  props<{ customerId: string }>()
);
export const actionLoadCustomerSuccess = createAction(
  '[Dashboard] Load Customer Success',
  props<{ currentCustomer: Customer }>()
);
export const actionLoadCustomerFailure = createAction(
  '[Dashboard] Load Customer Failure',
  props<{ errorLoadingCurrentCustomer: HttpErrorResponse }>()
);

// Load current customer information
export const actionLoadCustomerList = createAction(
  '[Dashboard] Load Customer List',
  props<{ customerSearch: CustomerSearch }>()
);
export const actionLoadCustomerListSuccess = createAction(
  '[Dashboard] Load Customer List Success',
  props<{ customerList: Customer[] }>()
);
export const actionLoadCustomerListFailure = createAction(
  '[Dashboard] Load Customer List Failure',
  props<{ errorLoadingCustomerList: HttpErrorResponse }>()
);

// Load selected customer tickets
export const actionLoadFieldTickets = createAction(
  '[Dashboard] Load Customer Field Tickets',
  props<{ customerId: string, pageNumber: number, rows: number}>()
);
export const actionLoadFieldTicketsSuccess = createAction(
  '[Dashboard] Load Customer Field Tickets Success',
  props<{ fieldTickets: FieldTicket[] }>()
);
export const actionLoadFieldTicketsFailure = createAction(
  '[Dashboard] Load Customer Field Tickets Failure',
  props<{ errorLoadingFieldTickets: HttpErrorResponse }>()
);

// Load selected customer tickets
export const actionLoadFieldTicketsFiltered = createAction(
  '[Dashboard] Load Field Filtered Tickets',
  props<{ fieldTicketFilter: FieldTicketSearch }>()
);
export const actionLoadFieldTicketsFilteredSuccess = createAction(
  '[Dashboard] Load Field Tickets Filtered Success',
  props<{ fieldTickets: FieldTicket[] }>()
);
export const actionLoadFieldTicketsFilteredFailure = createAction(
  '[Dashboard] Load Field Tickets Filtered Failure',
  props<{ errorLoadingFieldTickets: HttpErrorResponse }>()
);

// Load recent customer tickets
export const actionLoadFieldTicketsRecent = createAction(
  '[Dashboard] Load Recent Field Tickets',
  props<{ recentFieldTicketSearch: RecentFieldTicketSearch }>()
);
export const actionLoadFieldTicketsRecentSuccess = createAction(
  '[Dashboard] Load Recent Field Tickets Success',
  props<{ fieldTicketsRecent: FieldTicket[] }>()
);
export const actionLoadFieldTicketsRecentFailure = createAction(
  '[Dashboard] Load Recent Field Tickets Failure',
  props<{ errorLoadingFieldTicketsRecent: HttpErrorResponse }>()
);

// Load selected ticket data
export const actionFieldTicketLoadOne = createAction(
  '[Dashboard] Load Selected Field Ticket',
  props<{ fieldTicketId: string }>()
);
export const actionFieldTicketLoadOneSuccess = createAction(
  '[Dashboard] Load Selected Field Ticket Success',
  props<{ selectedFieldTicket: FieldTicket }>()
);
export const actionFieldTicketLoadOneFailure = createAction(
  '[Dashboard] Load Selected Field Ticket Failure',
  props<{ errorLoadingSelectedFieldTicket: HttpErrorResponse }>()
);

// Delete Field Ticket
export const actionDeleteFieldTicket = createAction(
  '[Dashboard] Delete Field Ticket',
  props<{ fieldTicketId: string }>()
);
export const actionDeleteFieldTicketSuccess = createAction(
  '[Dashboard] Delete Field Ticket Success'
);
export const actionDeleteFieldTicketFailure = createAction(
  '[Dashboard] Delete Field Ticket Failure',
  props<{ errorDeletingFieldTicket: HttpErrorResponse }>()
);

// Report graph data
export const actionReportLoadData = createAction(
  '[Dashboard] Load Report Data',
  props<{ fieldTicketId: string }>()
);
export const actionReportLoadDataSuccess = createAction(
  '[Dashboard] Load Report Data Success',
  props<{ chartData: FieldTicketGraphData }>()
);
export const actionReportLoadDataFailure = createAction(
  '[Dashboard] Load Report Data Failure',
  props<{ errorLoadingChartData: HttpErrorResponse }>()
);

// Report comparison graph data
export const actionLoadComparisonData = createAction(
  '[Dashboard] Load Comparison Data',
  props<{ fieldTicketIds: string[] }>()
);
export const actionLoadComparisonDataSuccess = createAction(
  '[Dashboard] Load Comparison Data Success',
  props<{ chartComparisonData: FieldTicketGraphComparisonData[] }>()
);
export const actionLoadComparisonDataFailure = createAction(
  '[Dashboard] Load Comparison Data Failure',
  props<{ errorLoadingChartComparisonData: HttpErrorResponse }>()
);

// Report comparison ovality data
export const actionLoadComparisonOvalityData = createAction(
  '[Dashboard] Load Comparison Ovality Data',
  props<{ fieldTicketIds: string[] }>()
);
export const actionLoadComparisonOvalityDataSuccess = createAction(
  '[Dashboard] Load Comparison Ovality Data Success',
  props<{ chartComparisonOvalityData: FieldTicketGraphComparisonOvalityPoints[] }>()
);
export const actionLoadComparisonOvalityDataFailure = createAction(
  '[Dashboard] Load Comparison Ovality Data Failure',
  props<{ errorLoadingChartComparisionOvalityData: HttpErrorResponse }>()
);

// Retrieve Field Ticket Project File
export const actionLoadProjectFile = createAction(
  '[Dashboard] Load Project File',
  props<{ fieldTicketId: string }>()
);
export const actionLoadProjectFileSuccess = createAction(
  '[Dashboard] Load Project File Success',
  props<{ projectFile: Blob }>()
);
export const actionLoadProjectFileFailure = createAction(
  '[Dashboard] Load Project File Failure',
  props<{ errorLoadingProjectFile: HttpErrorResponse }>()
);

// Create Customer
export const actionCreateCustomer = createAction(
  '[Dashboard] Create Customer',
  props<{ customerCreate: CustomerCreate }>()
);
export const actionCustomerCreateSuccess = createAction(
  '[Dashboard] Customer Create Success',
  props<{ customerId: string }>()
);
export const actionCustomerCreateFailure = createAction(
  '[Dashboard] Customer Create Failure',
  props<{ errorSavingCustomer: HttpErrorResponse }>()
);

// Update Customer
export const actionCustomerUpdate = createAction(
  '[Dashboard] Update Customer',
  props<{ customerId: string, customerUpdate: CustomerUpdate }>()
);
export const actionCustomerUpdateSuccess = createAction(
  '[Dashboard] Customer Update Success'
);
export const actionCustomerUpdateFailure = createAction(
  '[Dashboard] Customer Update Failure',
  props<{ errorSavingCustomer: HttpErrorResponse }>()
);

// Retrieve Ovality Inputs
export const actionRetrieveOvalityInputs = createAction(
  '[Dashboard] Retrieve Ovality Inputs',
  props<{ fieldTicketId: string }>()
);
export const actionRetrieveOvalityInputsSuccess= createAction(
  '[Dashboard] Retrieve Ovality Inputs Success',
  props<{ ovalityInputs: OvalityInput[] }>()
);
export const actionRetrieveOvalityInputsFailure = createAction(
  '[Dashboard] Retrieve Ovality Inputs Failure',
  props<{ errorLoadingOvalityInputs: HttpErrorResponse }>()
);

// Retrieve Annotated Image LIst
export const actionRetrieveAnnotatedImageList = createAction(
  '[Dashboard] Retrieve Annotated Image List',
  props<{ fieldTicketId: string, ovalityInputId: string }>()
);
export const actionRetrieveAnnotatedImageListSuccess= createAction(
  '[Dashboard] Retrieve Annotated Image List Success',
  props<{ annotatedImageList: AnnotatedImage[] }>()
);
export const actionRetrieveAnnotatedImageListFailure = createAction(
  '[Dashboard] Retrieve Annotated Image List Failure',
  props<{ errorLoadingAnnotatedImageList: HttpErrorResponse }>()
);

// Retrieve Ovality Input Image
export const actionSetCurrentOvalityInputId = createAction(
  '[Dashboard] Set Current Ovality Input Id',
  props<{ ovalityInputId: string }>()
);
export const actionRetrieveCurrentOvalityInputImage = createAction(
  '[Dashboard] Retrieve Current Ovality Input Image',
  props<{ fieldTicketId: string,
          ovalityInputId: string
        }>()
);
export const actionRetrieveOvalityInputImageSuccess= createAction(
  '[Dashboard] Retrieve Current Ovality Input Image Success',
  props<{ ovalityInputImage: OvalityInputImage }>()
);
export const actionRetrieveOvalityInputImageFailure = createAction(
  '[Dashboard] Retrieve Current Ovality Input Image Failure',
  props<{ errorLoadingOvalityInputImage: HttpErrorResponse }>()
);

