import { createAction, props } from '@ngrx/store';

export const authLogin = createAction(
 '[Auth] Login'
);
export const authLogout = createAction(
 '[Auth] Logout'
);

export const actionSetAuthenticated = createAction(
  '[Auth] Set Auth Status',
  props<{ authenticationStatus: boolean }>()
 );
export const actionSetCustomerId = createAction(
  '[Auth] Set CustomerId',
  props<{ customerId: string, role: string }>()
);
export const actionSetRole = createAction(
  '[Auth] Set Role',
  props<{ role: string }>()
);

export const actionResetUser = createAction(
  '[Auth] Reset Auth Status'
);

