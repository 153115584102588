<div class="container" fxLayoutAlign="center center" fxFlex="30">
  <mat-card fxLayout="column" class="m-b-20 m-t-20" fxFlex fxLayoutAlign="center center">
    <h1>401 - Unauthorized</h1>
    You currently do not have permission to view this page.

    <button mat-button aria-label="login"
            class="m-l-20 m-t-20 btn"
            (click)="navigateToDashboard()"
            matTooltip="Login">
      Return Home
    </button>
  </mat-card>
</div>
