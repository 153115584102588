import browser from 'browser-detect';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Store, select, ActionsSubject } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { environment as env } from '../../environments/environment';

import {
  routeAnimations,
  LocalStorageService,
  selectSettingsStickyHeader,
  selectEffectiveTheme
} from '../core/core.module';
import {
  actionSettingsChangeAnimationsPageDisabled
} from '../core/settings/settings.actions';
import { AuthService } from '../core/auth/auth.service';
import { SessionStorageService } from '../core/session-storage/session-storage.service';
import { ofType } from '@ngrx/effects';
import { actionSetRole } from '../core/auth/auth.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'cjcsm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit, OnDestroy {
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  logo = '../../assets/cjcsm_logo_small.png';
  navigation = [
    { link: 'dashboard', label: 'Dashboard' }, // Todo: Update this so it uses the customerId from the token
    // { link: 'login', label: 'Login' }
  ];
  navigationSideMenu = [
    ...this.navigation
  ];

  isAuthenticated$: Observable<boolean>;
  stickyHeader$: Observable<boolean>;
  theme$: Observable<string>;

  public isAuthorized = false;
  private navUpdated = false;
  private isAuthorizedSubscription: Subscription = new Subscription();
  private roleSubscription: Subscription = new Subscription();

  constructor(
    private store: Store,
    private storageService: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private authService: AuthService,
    private actionsSubject: ActionsSubject,
    private router: Router,
    private zone: NgZone
  ) {
    this.isAuthorizedSubscription = this.authService.getIsAuthorized().subscribe(
      (isAuthorized: boolean) => {
        this.isAuthorized = isAuthorized;
      });

    this.roleSubscription = this.actionsSubject
      .pipe(ofType(actionSetRole))
      .subscribe((action) => {
        const storedRole = action.role;

        if(storedRole && storedRole && this.canViewAdminDashboard(storedRole) && !this.navUpdated) {
          this.isAuthorized = true;

          this.navigation.pop();
          if(storedRole === 'admin') {
            this.navigation.push( { link: 'admin', label: 'Admin Dashboard' });
          }
          else if (storedRole === 'employee') {
            this.navigation.push( { link: 'admin', label: 'Employee Dashboard' });
          }
          this.navUpdated = true;
        }
      });
  }

  private canViewAdminDashboard(userRole: string): boolean {
    if(userRole && (userRole.toLowerCase() === 'admin' || userRole.toLowerCase() === 'employee'))
      return true;

    return false;
  }

  ngOnDestroy(): void {
    this.isAuthorizedSubscription.unsubscribe();
    this.roleSubscription.unsubscribe();
    this.authService.ngOnDestroy();
  }

  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name);
  }

  ngOnInit(): void {
    this.storageService.testLocalStorage();
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        actionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }



    // this.isAuthenticated$ = this.store.pipe(select(selectIsAuthenticated));
    this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader));
    this.theme$ = this.store.pipe(select(selectEffectiveTheme));

    this.authService.initAuth();

  }

  onLoginClick() {
    this.zone.run(() =>this.authService.login());
  }

  onLogoutClick() {
    this.authService.logout();
  }

}
