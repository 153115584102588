import { Injectable, OnDestroy, Inject, NgZone } from '@angular/core';
import {
  OidcSecurityService,
  OpenIdConfiguration,
  AuthWellKnownEndpoints,
  AuthorizationResult,
  AuthorizationState,
} from 'angular-auth-oidc-client';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../core/core.module';
import { actionResetUser, actionSetCustomerId, actionSetRole } from './auth.actions';
import { AuthState } from './auth.model';
import { selectCustomerId } from 'src/app/dashboard/dashboard.selectors';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from '../session-storage/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private isAuthorizedSubscription: Subscription = new Subscription();
  private isAuthorized = false;

  // Todo: Set these auth values to environment variables
  // private originUrl = 'http://localhost:4200';
  // private authUrl = 'https://localhost:44315';
  private originUrl = environment.cjcsmOriginUrl;
  private authUrl = environment.cjcsmAuthUrl;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private http: HttpClient,
    private router: Router,
    public store: Store<AppState>,
    private sessionStorage: SessionStorageService
  ) {}

  ngOnDestroy(): void {
    if (this.isAuthorizedSubscription) {
      this.isAuthorizedSubscription.unsubscribe();
    }
  }

  public initAuth() {
    const openIdConfiguration: OpenIdConfiguration = {
      stsServer: this.authUrl,
      redirect_url: this.originUrl + '/dashboard',
      client_id: 'cjcsm_client_app',
      response_type: 'code',
      scope: 'openid profile cjcsm-api.fieldTickets',
      post_logout_redirect_uri: this.originUrl + '/dashboard',
      forbidden_route: '/forbidden',
      unauthorized_route: '/unauthorized',
      silent_renew: true,
      silent_renew_url: this.originUrl + '/silent-renew.html',
      history_cleanup_off: true,
      auto_userinfo: true,
      log_console_warning_active: false,
      log_console_debug_active: false,
      max_id_token_iat_offset_allowed_in_seconds: 10,
    };

    const authWellKnownEndpoints: AuthWellKnownEndpoints = {
      issuer: this.authUrl,
      jwks_uri: this.authUrl + '/.well-known/openid-configuration/jwks',
      authorization_endpoint: this.authUrl + '/connect/authorize',
      token_endpoint: this.authUrl + '/connect/token',
      userinfo_endpoint: this.authUrl + '/connect/userinfo',
      end_session_endpoint: this.authUrl + '/connect/endsession',
      check_session_iframe: this.authUrl + '/connect/checksession',
      revocation_endpoint: this.authUrl + '/connect/revocation',
      introspection_endpoint: this.authUrl + '/connect/introspect',
    };

    this.oidcSecurityService.setupModule(
      openIdConfiguration,
      authWellKnownEndpoints
    );

    if (this.oidcSecurityService.moduleSetup) {
      this.doCallbackLogicIfRequired();
    } else {
      this.oidcSecurityService.onModuleSetup.subscribe(() => {
        this.doCallbackLogicIfRequired();
      });
    }
    this.isAuthorizedSubscription = this.oidcSecurityService
      .getIsAuthorized()
      .subscribe((isAuthorized) => {
        this.isAuthorized = isAuthorized;
      });

    this.oidcSecurityService.onAuthorizationResult.subscribe(
      (authorizationResult: AuthorizationResult) => {
       this.onAuthorizationResultComplete(authorizationResult);
      }
    );
  }

  private onAuthorizationResultComplete(
    authorizationResult: AuthorizationResult
  ) {
    const token = this.oidcSecurityService.getToken();
    const decodedToken = atob(token.split('.')[1]);
    const jsonToken = JSON.parse(decodedToken);
    const customerId = jsonToken.customer_id;
    const role = jsonToken.role;

    this.sessionStorage.setItem('customerId', { customerId: customerId });
    this.sessionStorage.setItem('role', { role: role });

    this.store.dispatch(actionSetRole({ role: role }));
    this.store.dispatch(actionSetCustomerId({ customerId: customerId, role: role }));

    // console.log('--------------------------');
    // console.log(role);
    // console.log(customerId);
    // console.log(jsonToken);

    // console.log(
    //   'Auth result received AuthorizationState:' +
    //     authorizationResult.authorizationState +
    //     ' validationResult:' +
    //     authorizationResult.validationResult
    // );

    // Check if role is there, if not, then authorize

    if (authorizationResult.authorizationState === AuthorizationState.unauthorized) {
      if (window.parent) {
        // sent from the child iframe, for example the silent renew
        this.router.navigate(['/unauthorized']);
      } else {
        window.location.href = '/unauthorized';
      }
   }

  }

  private doCallbackLogicIfRequired() {
    this.oidcSecurityService.authorizedCallbackWithCode(
      window.location.toString()
    );
  }

  getIsAuthorized(): Observable<boolean> {
    return this.oidcSecurityService.getIsAuthorized();
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  logout() {
    this.oidcSecurityService.logoff();
    this.store.dispatch(actionResetUser());
  }

  get(url: string): Observable<any> {
    return this.http.get(url, { headers: this.getHeaders() }).pipe(
      catchError((error) => {
        this.oidcSecurityService.handleError(error);
        return throwError(error);
      })
    );
  }

  post(url: string, data: any): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.post(url, body, { headers: this.getHeaders() }).pipe(
      catchError((error) => {
        this.oidcSecurityService.handleError(error);
        return throwError(error);
      })
    );
  }

  put(url: string, data: any): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.put(url, body, { headers: this.getHeaders() }).pipe(
      catchError((error) => {
        this.oidcSecurityService.handleError(error);
        return throwError(error);
      })
    );
  }

  delete(url: string): Observable<any> {
    return this.http.delete(url, { headers: this.getHeaders() }).pipe(
      catchError((error) => {
        this.oidcSecurityService.handleError(error);
        return throwError(error);
      })
    );
  }

  getHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers.set('Accept','*/*')
    return this.appendAuthHeader(headers);
  }

  getBlobHeaders() {
    let headers = new HttpHeaders();
    return this.appendAuthHeader(headers);
  }

  public getToken() {
    const token = this.oidcSecurityService.getToken();
    return token;
  }

  private appendAuthHeader(headers: HttpHeaders) {
    const token = this.oidcSecurityService.getToken();

    if (token === '') {
      return headers;
    }

    const tokenValue = 'Bearer ' + token;
    return headers.set('Authorization', tokenValue);
  }
}
