import { Action, createReducer, on} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

import { DashboardState } from './dashboard.state';
import { actionReportLoadData,
         actionReportLoadDataSuccess,
         actionReportLoadDataFailure,
         actionLoadCustomer,
         actionLoadCustomerSuccess,
         actionLoadCustomerFailure,
         actionLoadFieldTickets,
         actionLoadFieldTicketsFailure,
         actionLoadFieldTicketsSuccess,
         actionLoadFieldTicketsFiltered,
         actionLoadFieldTicketsFilteredSuccess,
         actionLoadFieldTicketsFilteredFailure,
         actionLoadProjectFile,
         actionLoadProjectFileSuccess,
         actionLoadProjectFileFailure,
         actionCreateCustomer,
         actionCustomerCreateSuccess,
         actionCustomerCreateFailure,
         actionRetrieveOvalityInputs,
         actionRetrieveOvalityInputsSuccess,
         actionRetrieveOvalityInputsFailure,
         actionFieldTicketLoadOne,
         actionFieldTicketLoadOneSuccess,
         actionFieldTicketLoadOneFailure,
         actionSetCurrentOvalityInputId,
         actionRetrieveCurrentOvalityInputImage,
         actionRetrieveOvalityInputImageSuccess,
         actionRetrieveOvalityInputImageFailure,
         actionLoadFieldTicketsRecent,
         actionLoadFieldTicketsRecentSuccess,
         actionLoadFieldTicketsRecentFailure,
         actionLoadCustomerList,
         actionLoadCustomerListSuccess,
         actionLoadCustomerListFailure,
         actionLoadComparisonData,
         actionLoadComparisonDataSuccess,
         actionLoadComparisonDataFailure,
         actionCustomerUpdate,
         actionCustomerUpdateSuccess,
         actionCustomerUpdateFailure,
         actionRetrieveAnnotatedImageList,
         actionRetrieveAnnotatedImageListSuccess,
         actionRetrieveAnnotatedImageListFailure,
         actionLoadComparisonOvalityData,
         actionLoadComparisonOvalityDataSuccess,
         actionLoadComparisonOvalityDataFailure,
         actionDeleteFieldTicket,
         actionDeleteFieldTicketSuccess,
         actionDeleteFieldTicketFailure} from './dashboard.actions';
import { FieldTicket } from './models/field-ticket.model';
import { selectAllProjectImages } from './dashboard.selectors';

export function sortByName(a: FieldTicket, b: FieldTicket): number {
  return a.fieldTicket2021Id.localeCompare(b.fieldTicket2021Id);
}

export const dashboardAdapter: EntityAdapter<FieldTicket> = createEntityAdapter<FieldTicket>({
  sortComparer: sortByName
});

// NOTE: Use EntityAdapter for tickets - probably a list of recent tickets to keep the number
// loaded in memory to a reasonable amount. Like last 10 or something.
export const initialState: DashboardState = dashboardAdapter.getInitialState({
  ids: [],
  entities: { },
  loadingCustomerTickets: false,
  errorLoadingCustomerTickets: null,

  loadingChartData: false,
  chartData: null,
  errorLoadingChartData: null,

  loadingChartComparisonData: false,
  chartComparisonData: null,
  errorLoadingChartComparisonData: null,

  loadingChartComparisonOvalityData: false,
  chartComparisonOvalityData: null,
  errorLoadingChartComparisonOvalityData: null,

  currentCustomer: null,
  loadingCurrentCustomer: true,
  errorLoadingCurrentCustomer: null,

  customerList: null,
  loadingCustomerList: true,
  errorLoadingCustomerList: null,

  fieldTickets: null,
  loadingFieldTickets: true,
  errorLoadingFieldTickets: null,

  fieldTicketsRecent: null,
  loadingFieldTicketsRecent: true,
  errorLoadingFieldTicketsRecent: null,

  selectedFieldTicket: null,
  loadingSelectedFieldTicket: true,
  errorLoadingSelectedFieldTicket: null,

  deletedFieldTicketId: null,
  deletingFieldTicket: false,
  errorDeletingFieldTicket: null,

  projectFile: null,
  loadingProjectFile: false,
  errorLoadingProjectFile: null,

  customerId: null,
  savingCustomer: false,
  errorSavingCustomer: null,

  ovalityInputs: null,
  loadingOvalityInputs: false,
  errorLoadingOvalityInputs: null,

  annotatedImageList: null,
  loadingAnnotatedImageList: false,
  errorLoadingAnnotatedImageList: null,

  currentOvalityInputId: null,
  projectImages: null,
  loadingImage: false,
  errorLoadingImage: null
});

const reducer = createReducer(
  initialState,
  on(actionLoadCustomer, (state, { customerId }) => ({
    ...state,
    currentCustomer: null,
    loadingCurrentCustomer: true,
    errorLoadingCurrentCustomer: null
  })),
  on(actionLoadCustomerSuccess, (state, { currentCustomer }) => ({
    ...state,
    currentCustomer,
    loadingCurrentCustomer: false,
    errorLoadingCurrentCustomer: null
  })),
  on(actionLoadCustomerFailure, (state, { errorLoadingCurrentCustomer }) => ({
    ...state,
    loadingChartData: false,
    errorLoadingCurrentCustomer
  })),

  on(actionLoadCustomerList, (state, { }) => ({
    ...state,
    customerList: null,
    loadingCustomerList: true,
    errorLoadingCustomerList: null
  })),
  on(actionLoadCustomerListSuccess, (state, { customerList }) => ({
    ...state,
    customerList,
    loadingCustomerList: false,
    errorLoadingCustomerList: null
  })),
  on(actionLoadCustomerListFailure, (state, { errorLoadingCustomerList }) => ({
    ...state,
    loadingCustomerList: false,
    errorLoadingCustomerList
  })),

  on(actionReportLoadData, (state, { fieldTicketId }) => ({
    ...state,
    loadingChartData: true,
    chartData: null,
    errorLoadingChartData: null
  })),
  on(actionReportLoadDataSuccess, (state, { chartData }) => ({
    ...state,
    loadingChartData: false,
    chartData,
    errorLoadingChartData: null
  })),
  on(actionReportLoadDataFailure, (state, { errorLoadingChartData }) => ({
    ...state,
    loadingChartData: false,
    errorLoadingChartData
  })),

  on(actionLoadComparisonData, (state, { fieldTicketIds }) => ({
    ...state,
    loadingChartComparisonData: true,
    chartComparisonData: null,
    errorLoadingChartComparisionData: null
  })),
  on(actionLoadComparisonDataSuccess, (state, { chartComparisonData }) => ({
    ...state,
    loadingChartComparisonData: false,
    chartComparisonData: chartComparisonData,
    errorLoadingChartComparisionData: null
  })),
  on(actionLoadComparisonDataFailure, (state, { errorLoadingChartComparisonData }) => ({
    ...state,
    loadingChartComparisonData: false,
    errorLoadingChartComparisonData
  })),

  on(actionLoadComparisonOvalityData, (state, { fieldTicketIds }) => ({
    ...state,
    loadingChartComparisonOvalityData: true,
    chartComparisonOvalityData: null,
    errorLoadingChartComparisionOvalityData: null
  })),
  on(actionLoadComparisonOvalityDataSuccess, (state, { chartComparisonOvalityData }) => ({
    ...state,
    loadingChartComparisonOvalityData: false,
    chartComparisonOvalityData: chartComparisonOvalityData,
    errorLoadingChartComparisionOvalityData: null
  })),
  on(actionLoadComparisonOvalityDataFailure, (state, { errorLoadingChartComparisionOvalityData }) => ({
    ...state,
    loadingChartComparisonOvalityData: false,
    errorLoadingChartComparisionOvalityData
  })),

  on(actionLoadFieldTickets, (state, { customerId, pageNumber, rows }) => ({
    ...state,
    loadingFieldTickets: true,
    errorLoadingFieldTickets: null
  })),
  // on(actionLoadFieldTicketsSuccess, (state, { fieldTickets }) =>
  //   dashboardAdapter.addMany(fieldTickets, state)
  // ),
  on(actionLoadFieldTicketsSuccess, (state, { fieldTickets }) => ({
    ...state,
    fieldTickets: fieldTickets['fieldTickets'],
    loadingFieldTickets: false,
    errorLoadingFieldTickets: null
  })),
  on(actionLoadFieldTicketsFailure, (state, { errorLoadingFieldTickets }) => ({
    ...state,
    loadingFieldTickets: false,
    errorLoadingFieldTickets
  })),

  on(actionFieldTicketLoadOne, (state, { fieldTicketId }) => ({
    ...state,
    selectedFieldTicket: null,
    loadingSelectedFieldTicket: true,
    errorLoadingSelectedFieldTicket: null
  })),
  on(actionFieldTicketLoadOneSuccess, (state, { selectedFieldTicket }) => ({
    ...state,
    selectedFieldTicket,
    loadingSelectedFieldTicket: false,
    errorLoadingFieldTickets: null
  })),
  on(actionFieldTicketLoadOneFailure, (state, { errorLoadingSelectedFieldTicket }) => ({
    ...state,
    loadingFieldTickets: false,
    errorLoadingSelectedFieldTicket
  })),

  // Delete Field Ticket
  on(actionDeleteFieldTicket, (state, { fieldTicketId }) => ({
    ...state,
    deletedFieldTicketId: fieldTicketId,
    deletingFieldTicket: true,
    errorDeletingFieldTickets: null
  })),
  on(actionDeleteFieldTicketSuccess, (state, { }) => ({
    ...state,
    deletedFieldTicketId: null,
    deletingFieldTicket: false,
    errorDeletingFieldTickets: null
  })),
  on(actionDeleteFieldTicketFailure, (state, { errorDeletingFieldTicket }) => ({
    ...state,
    deletedFieldTicketId: null,
    deletingFieldTicket: false,
    errorDeletingFieldTickets: errorDeletingFieldTicket
  })),

  on(actionLoadFieldTicketsFiltered, (state, { fieldTicketFilter }) => ({
    ...state,
    loadingFieldTickets: true,
    errorLoadingFieldTickets: null
  })),
  on(actionLoadFieldTicketsFilteredSuccess, (state, { fieldTickets }) => ({
    ...state,
    fieldTickets: fieldTickets['fieldTickets'],
    loadingFieldTickets: false,
    errorLoadingFieldTickets: null
  })),
  on(actionLoadFieldTicketsFilteredFailure, (state, { errorLoadingFieldTickets }) => ({
    ...state,
    loadingFieldTickets: false,
    errorLoadingFieldTickets
  })),

  on(actionLoadFieldTicketsRecent, (state, { recentFieldTicketSearch }) => ({
    ...state,
    loadingFieldTicketsRecent: true,
    errorLoadingFieldTicketsRecent: null
  })),
  on(actionLoadFieldTicketsRecentSuccess, (state, { fieldTicketsRecent }) => ({
    ...state,
    fieldTicketsRecent,
    loadingFieldTicketsRecent: false,
    errorLoadingFieldTicketsRecent: null
  })),
  on(actionLoadFieldTicketsRecentFailure, (state, { errorLoadingFieldTicketsRecent }) => ({
    ...state,
    loadingFieldTicketsRecent: false,
    errorLoadingFieldTicketsRecent
  })),

  on(actionLoadProjectFile, (state, { fieldTicketId }) => ({
    ...state,
    loadingProjectFile: true,
    errorLoadingProjectFile: null
  })),
  on(actionLoadProjectFileSuccess, (state, { projectFile }) => ({
    ...state,
    projectFile,
    loadingProjectFile: false,
    errorLoadingProjectFile: null
  })),
  on(actionLoadProjectFileFailure, (state, { errorLoadingProjectFile }) => ({
    ...state,
    loadingProjectFile: false,
    errorLoadingProjectFile
  })),

  on(actionCreateCustomer, (state, { customerCreate }) => ({
    ...state,
    savingCustomer: true,
    errorSavingCustomer: null
  })),
  on(actionCustomerCreateSuccess, (state, { customerId }) => ({
    ...state,
    customerId,
    savingCustomer: false,
    errorSavingCustomer: null
  })),
  on(actionCustomerCreateFailure, (state, { errorSavingCustomer }) => ({
    ...state,
    savingCustomer: false,
    errorSavingCustomer
  })),

  on(actionCustomerUpdate, (state, { customerId, customerUpdate }) => ({
    ...state,
    savingCustomer: true,
    errorSavingCustomer: null
  })),
  on(actionCustomerUpdateSuccess, (state, { }) => ({
    ...state,
    savingCustomer: false,
    errorSavingCustomer: null
  })),
  on(actionCustomerUpdateFailure, (state, { errorSavingCustomer }) => ({
    ...state,
    savingCustomer: false,
    errorSavingCustomer
  })),

  on(actionRetrieveOvalityInputs, (state, { fieldTicketId }) => ({
    ...state,
    loadingOvalityInputs: true,
    errorLoadingOvalityInputs: null
  })),
  on(actionRetrieveOvalityInputsSuccess, (state, { ovalityInputs }) => ({
    ...state,
    ovalityInputs,
    loadingOvalityInputs: false,
    errorLoadingOvalityInputs: null
  })),
  on(actionRetrieveOvalityInputsFailure, (state, { errorLoadingOvalityInputs }) => ({
    ...state,
    loadingOvalityInputs: false,
    errorLoadingOvalityInputs
  })),

  on(actionRetrieveAnnotatedImageList, (state, { fieldTicketId, ovalityInputId }) => ({
    ...state,
    loadingAnnotatedImageList: true,
    errorLoadingAnnotatedImageList: null
  })),
  on(actionRetrieveAnnotatedImageListSuccess, (state, { annotatedImageList }) => ({
    ...state,
    annotatedImageList,
    loadingAnnotatedImageList: false,
    errorLoadingAnnotatedImageList: null
  })),
  on(actionRetrieveAnnotatedImageListFailure, (state, { errorLoadingAnnotatedImageList }) => ({
    ...state,
    loadingAnnotatedImageList: false,
    errorLoadingAnnotatedImageList
  })),

  on(actionSetCurrentOvalityInputId, (state, { ovalityInputId }) => ({
    ...state,
    currentOvalityInputId: ovalityInputId
  })),
  on(actionRetrieveCurrentOvalityInputImage, (state, {  }) => ({
    ...state,
    loadingImage: true,
    errorLoadingOvalityInputs: null
  })),
  on(actionRetrieveOvalityInputImageSuccess, (state, { ovalityInputImage }) => ({
    ...state,
    projectImages: [ovalityInputImage],
    loadingImage: false,
    errorLoadingImage: null
  })),
  on(actionRetrieveOvalityInputImageFailure, (state, { errorLoadingOvalityInputImage }) => ({
    ...state,
    loadingImage: false,
    errorLoadingImage: errorLoadingOvalityInputImage
  }))
);

export function dashboardReducer(state: DashboardState | undefined, action: Action) {
  return reducer(state, action);
}

