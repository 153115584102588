import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';

// import { selectIsAuthenticated } from './auth.selectors';
import { AppState } from '../core.state';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, OnDestroy {

  private isAuthorizedSubscription: Subscription = new Subscription();


  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable<boolean>(obs => {
      this.isAuthorizedSubscription = this.authService.getIsAuthorized().subscribe(
        (isAuthorized: boolean) => {
          if(isAuthorized) {
            return true;
          }

          return this.router.parseUrl('/unauthorized');
          // obs.next(isAuthorized);
        });
    });
  }

  ngOnDestroy() {
    this.isAuthorizedSubscription.unsubscribe();
  }
}
