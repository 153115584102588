<div class="container" fxLayoutAlign="center center" fxFlex="30">
  <mat-card fxLayout="column" class="m-b-20 m-t-20" fxFlex fxLayoutAlign="center center">
    <h1>404 - Page Not Found</h1>
    The page you are looking for does not exist.

    <button mat-button aria-label="login"
            class="m-l-20 m-t-20 btn"
            (click)="navigateToDashboard()"
            matTooltip="Login">
      Return Home
    </button>
  </mat-card>
</div>
