<div [class]="'theme-wrapper ' + (theme$ | async)">

  <mat-sidenav-container>

    <mat-sidenav #sidenav mode="push">
      <div class="branding">
        <img [src]="logo" />
        <span class="m-l-15" *ngIf="!isProd"> TEST</span>
      </div>
      <mat-nav-list>
        <a mat-list-item *ngFor="let item of navigationSideMenu" (click)="sidenav.close()" [routerLink]="[item.link]"
          routerLinkActive="active">
          {{item.label}}
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <div class="wrapper">

      <div class="toolbar" [style.position]="(stickyHeader$ | async) ? 'fixed' : 'inherit'" [class.mat-elevation-z4]="(stickyHeader$ | async)">
        <mat-toolbar color="primary">
          <button mat-icon-button class="d-md-none" (click)="sidenav.open()">
            <fa-icon icon="bars"></fa-icon>
          </button>

          <span routerLink="" class="branding spacer center d-inline d-sm-none">
            <img [src]="logo" />
            <span class="m-l-15" *ngIf="!isProd"> TEST</span>
          </span>
          <span routerLink="" class="branding spacer center d-none d-sm-inline d-md-none">
            <img [src]="logo" />
            <span class="m-l-15" *ngIf="!isProd"> TEST</span>
          </span>
          <span routerLink="" class="branding spacer d-none d-md-inline">
            <img [src]="logo" />
            <span class="m-l-15" *ngIf="!isProd"> TEST</span>
          </span>

          <span class="d-none d-md-inline">
            <button mat-button class="nav-button" *ngFor="let item of navigation" [routerLink]="[item.link]"
              routerLinkActive="active">
              {{item.label}}
            </button>
          </span>

          <!-- <button mat-button mat-stroked-button *ngIf="!(isAuthenticated$ | async)" (click)="onLoginClick()"> -->
          <button mat-button mat-stroked-button *ngIf="!isAuthorized" (click)="onLoginClick()">
            Login
          </button>

          <!-- <button *ngIf="(isAuthenticated$ | async)" -->
          <button *ngIf="isAuthorized"
                  mat-icon-button
                  [matMenuTriggerFor]="toolbarUserMenu">
            <fa-icon icon="user-circle"></fa-icon>
          </button>
          <mat-menu #toolbarUserMenu="matMenu">
            <button mat-menu-item (click)="onLogoutClick()">
              <mat-icon><fa-icon icon="power-off"></fa-icon></mat-icon>
              <span>Logout</span>
            </button>
          </mat-menu>

          <!-- <button mat-icon-button routerLink="settings" class="d-none d-sm-inline">
            <fa-icon icon="cog"></fa-icon>
          </button> -->

        </mat-toolbar>
      </div>

      <div class="content" [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig.data && o.activatedRoute.routeConfig.data.title">
        <router-outlet #o="outlet"></router-outlet>
      </div>

      <div class="footer">
        <div class="row">
          <div class="col-sm-12 links">
            <!-- <a href="https://www.github.com/tomastrajan" target="_blank">
              <fa-icon [icon]="['fab','github']"></fa-icon>
              <span>Github</span>
            </a>
            <a href="https://www.medium.com/@tomastrajan" target="_blank">
              <fa-icon [icon]="['fab','medium-m']"></fa-icon>
              <span>Medium</span>
            </a>
            <a href="https://www.twitter.com/tomastrajan" target="_blank">
              <fa-icon [icon]="['fab','twitter']"></fa-icon>
              <span>Twitter</span>
            </a>
            <a href="https://www.youtube.com/channel/UC7XgRHIVoqnh3U5Vmly9ofQ"
               target="_blank">
              <fa-icon [icon]="['fab','youtube']"></fa-icon>
              <span>Youtube</span>
            </a>
            <a href="https://www.instagram.com/tomastrajan" target="_blank">
              <fa-icon [icon]="['fab','instagram']"></fa-icon>
              <span>Instagram</span>
            </a>
            <a href="https://www.slides.com/tomastrajan" target="_blank">
              <fa-icon icon="play-circle"></fa-icon>
              <span>Slides</span>
            </a> -->
          </div>
        </div>
        <div class="row">
          <div class="col-12 signature">
            &#169; <span class="year">{{year}}</span> - CJCSM Inspection
            <br class="d-block d-sm-none">
              <span *ngIf="!isProd">{{version}} [{{envName}}]</span>
          </div>
        </div>
      </div>

    </div>

  </mat-sidenav-container>

</div>
