import { Injectable } from '@angular/core';
import { ofType, createEffect, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AppState } from '../core.state';

import { LocalStorageService } from '../local-storage/local-storage.service';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { actionResetUser, actionSetAuthenticated, actionSetCustomerId, actionSetRole, authLogin, authLogout } from './auth.actions';

export const AUTH_KEY = 'AUTH';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    public store: Store<AppState>
  ) {}

  setAuthStatus = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionSetAuthenticated),
        tap(() =>
          this.sessionStorageService.setItem('authStatus', { isAuthenticated: true })
        )
      ),
    { dispatch: false }
  );

  setUser = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionSetCustomerId),
        tap((action) => {
          this.sessionStorageService.setItem('customerId', { customerId: action.customerId });
          this.sessionStorageService.setItem('role', { role: action.role });
        }
        )
      ),
    { dispatch: false }
  );

  setRole = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionSetRole),
        tap((action) =>
          this.sessionStorageService.setItem('role', { role: action.role })
        )
      ),
    { dispatch: false }
  );

  resetUser = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionResetUser),
        tap((action) => {
          this.sessionStorageService.removeItem('customerId')
          this.sessionStorageService.removeItem('role')
        })
      ),
    { dispatch: false }
  );

}
