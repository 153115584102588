import { AuthState } from './auth.model';
import { createReducer, on, Action } from '@ngrx/store';

import { actionResetUser,
         actionSetAuthenticated,
         actionSetCustomerId,
         actionSetRole } from './auth.actions';

export const initialState: AuthState = {
  isAuthenticated: false,
  customerId: null,
  role: null
};

const reducer = createReducer(
  initialState,
  on(actionSetAuthenticated, (state, { authenticationStatus }) => ({
    ...state,
    isAuthenticated: authenticationStatus
  })),
  on(actionSetCustomerId, (state, { customerId, role }) => ({
    ...state,
    customerId: customerId,
    role: role
  })),
  on(actionSetRole, (state, { role }) => ({
    ...state,
    role: role
  })),
  on(actionResetUser, (state, { }) => ({
    ...state,
    customerId: null,
    role: null
  }))
);

export function authReducer(state: AuthState | undefined,action: Action): AuthState {
  return reducer(state, action);
}
